<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import 'prismjs';
import 'prismjs/themes/prism.css';

export default {
    data() {
        return {
            title: "Lists",
            items: [
                {
                    text: "Base UI",
                    href: "/",
                },
                {
                    text: "Lists",
                    active: true,
                },
            ],
        };
    },
    name: "Lists",
    components: {
        Layout,
        PageHeader,
    },
    mounted() {
        const checkbox = document.getElementsByClassName("code-switcher");
        Array.from(checkbox).forEach((check) => {
            check.addEventListener('change', () => {
                const card = check.closest('.card');
                const preview = card.querySelector(".live-preview");
                const code = card.querySelector(".code-view");
                if (check.checked) {
                    // do this
                    preview.classList.add("d-none");
                    code.classList.remove("d-none");
                } else {
                    // do that
                    preview.classList.remove("d-none");
                    code.classList.add("d-none");
                }
            });
        });
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <b-row>
            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Default List</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="basiclist-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox" id="basiclist-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>list-group</code> class in an unordered list and
                            <code>list-group-item</code> class to create a default list group.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <i class="ri-bill-line align-middle me-2"></i> Send the billing agreement
                                </li>
                                <li class="list-group-item">
                                    <i class="ri-file-copy-2-line align-middle me-2"></i>Send over all the
                                    documentation.
                                </li>
                                <li class="list-group-item">
                                    <i class="ri-question-answer-line align-middle me-2"></i>Meeting with daron to
                                    review the intake form
                                </li>
                                <li class="list-group-item">
                                    <i class="ri-secure-payment-line align-middle me-2"></i>Check uikings theme and give
                                    customer support
                                </li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup">
<code>&lt;b-list-group;&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;ri-bill-line align-middle me-2&quot;&gt;&lt;/i&gt; Send the billing agreement&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;ri-file-copy-2-line align-middle me-2&quot;&gt;&lt;/i&gt;Send over all the documentation.&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;ri-question-answer-line align-middle me-2&quot;&gt;&lt;/i&gt;Meeting with daron to review the intake form&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;ri-secure-payment-line align-middle me-2&quot;&gt;&lt;/i&gt;Check uikings theme and give customer support&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Active Item</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="listactive-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="listactive-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>active</code> class to
                            <code>list-group-item</code> to indicate the current active selection.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item active" aria-current="true">Send the billing agreement</li>
                                <li class="list-group-item">Send over all the documentation.</li>
                                <li class="list-group-item">Meeting with daron to review the intake form</li>
                                <li class="list-group-item">Check uikings theme and give customer support</li>
                                <li class="list-group-item">Start making a presentation</li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group&gt;
&lt;b-list-group-item active&quot; aria-current=&quot;true&quot;&gt;Send the billing agreement&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;Send over all the documentation.&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;Meeting with daron to review the intake form&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;Check uikings theme and give customer support&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;Start making a presentation&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Disabled Items</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="listdisabled-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="listdisabled-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>disabled</code> class to
                            <code>list-group-item</code> to make it
                            <em>appear</em> disabled.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item disabled" aria-disabled="true">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-1.jpg" alt
                                                class="avatar-xs rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-2">James Ballard</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-2.jpg" alt
                                                class="avatar-xs rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-2">Nancy Martino</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-3.jpg" alt
                                                class="avatar-xs rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-2">Henry Baird</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-3.jpg" alt
                                                class="avatar-xs rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-2">Erica Kernan</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 215px;"><code>&lt;b-list-group&gt;
&lt;b-list-group-item disabled&quot; aria-disabled=&quot;true&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded-circle&quot;&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-2&quot;&gt;
James Ballard
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded-circle&quot;&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-2&quot;&gt;
Nancy Martino
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded-circle&quot;&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-2&quot;&gt;
Henry Baird
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded-circle&quot;&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-2&quot;&gt;
Erica Kernan
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;/list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Link</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="listlink-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox" id="listlink-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>&lt;a&gt;</code> tag to create actionable list group items with hover, disabled, and
                            active states by adding list-group-item-action.
                        </p>
                        <div class="live-preview">
                            <div class="list-group list-group-fill-success">
                                <b-link href="#" class="list-group-item list-group-item-action active">
                                    <i class="ri-download-2-fill align-middle me-2"></i>Category Download
                                </b-link>
                                <b-link href="#" class="list-group-item list-group-item-action">
                                    <i class="ri-shield-check-line align-middle me-2"></i>Security Access
                                </b-link>
                                <b-link href="#" class="list-group-item list-group-item-action">
                                    <i class="ri-database-2-line align-middle me-2"></i>Storage folder
                                </b-link>
                                <b-link href="#" class="list-group-item list-group-item-action">
                                    <i class="ri-notification-3-line align-middle me-2"></i>Push Notification
                                </b-link>
                                <b-link href="#" class="list-group-item list-group-item-action disabled" tabindex="-1">
                                    <i class="ri-moon-fill align-middle me-2"></i>Dark Mode
                                </b-link>
                            </div>
                        </div>

                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group class=&quot;list-group-fill-success&quot;&gt;
&lt;b-list-group-item active href=&quot;#&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-download-2-fill align-middle me-2&quot;&gt;&lt;/i&gt;Category Download&lt;/b-list-group-item&gt;
&lt;b-list-group-item href=&quot;#&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-shield-check-line align-middle me-2&quot;&gt;&lt;/i&gt;Security Access&lt;/b-list-group-item&gt;
&lt;b-list-group-item href=&quot;#&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-database-2-line align-middle me-2&quot;&gt;&lt;/i&gt;Storage folder&lt;/b-list-group-item&gt;
&lt;b-list-group-item href=&quot;#&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-notification-3-line align-middle me-2&quot;&gt;&lt;/i&gt;Push Notification&lt;/b-list-group-item&gt;
&lt;b-list-group-item disabled href=&quot;#&quot; class=&quot;list-group-item-action&quot; tabindex=&quot;-1&quot; aria-disabled=&quot;true&quot;&gt;&lt;i class=&quot;ri-moon-fill align-middle me-2&quot;&gt;&lt;/i&gt;Dark Mode&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Button</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="listbutton-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="listbutton-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>&lt;button&gt;</code> tag to create actionable list group items with hover, disabled,
                            and active states by adding list-group-item-action.
                        </p>
                        <div class="live-preview">
                            <div class="list-group">
                                <button type="button" class="list-group-item list-group-item-action active"
                                    aria-current="true">
                                    <i class="ri-reply-fill align-middle me-2"></i>Reply
                                </button>
                                <button type="button" class="list-group-item list-group-item-action">
                                    <i class="ri-share-forward-fill align-middle me-2"></i>Forward Message
                                </button>
                                <button type="button" class="list-group-item list-group-item-action">
                                    <i class="ri-equalizer-line align-middle me-2"></i>Filter Message
                                </button>
                                <button type="button" class="list-group-item list-group-item-action">
                                    <i class="ri-delete-bin-5-line align-middle me-2"></i>Delete Message
                                </button>
                                <button type="button" class="list-group-item list-group-item-action" disabled>
                                    <i class="ri-forbid-line align-middle me-2"></i>Block "Mark Spencer
                                </button>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;div class=&quot;b-list-group&quot;&gt;
&lt;b-list-group-item tag=&quot;button&quot; class=&quot;list-group-item-action active&quot; aria-current=&quot;true&quot;&gt;&lt;i class=&quot;ri-reply-fill align-middle me-2&quot;&gt;&lt;/i&gt;Reply&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;button&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-share-forward-fill align-middle me-2&quot;&gt;&lt;/i&gt;Forward Message&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;button&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-equalizer-line align-middle me-2&quot;&gt;&lt;/i&gt;Filter Message&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;button&quot; class=&quot;list-group-item-action&quot;&gt;&lt;i class=&quot;ri-delete-bin-5-line align-middle me-2&quot;&gt;&lt;/i&gt;Delete Message&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;button&quot; class=&quot;list-group-item-action&quot; disabled&gt;&lt;i class=&quot;ri-forbid-line align-middle me-2&quot;&gt;&lt;/i&gt;Block &quot;Mark Spencer&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Flush List</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="flushlist-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox" id="flushlist-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>list-group-flush</code> class to remove some borders and rounded corners to render
                            list group items.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Send the billing agreement</li>
                                <li class="list-group-item">Send over all the documentation.</li>
                                <li class="list-group-item">Meeting with daron to review the intake form</li>
                                <li class="list-group-item">Check uikings theme and give customer support</li>
                                <li class="list-group-item">Start making a presentation</li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group flush&quot;&gt;
&lt;b-list-group-item&gt;Send the billing agreement&lt;/list-group-item&gt;
&lt;b-list-group-item&gt;Send over all the documentation.&lt;/list-group-item&gt;
&lt;b-list-group-item&gt;Meeting with daron to review the intake form&lt;/list-group-item&gt;
&lt;b-list-group-item&gt;Check uikings theme and give customer support&lt;/list-group-item&gt;
&lt;b-list-group-item&gt;Start making a presentation&lt;/list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xl="12">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Horizontal List</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="horilist-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox" id="horilist-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>list-group-horizontal</code> class to change the layout of list group items from
                            vertical to horizontal across all breakpoints.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group list-group-horizontal-md mb-3">
                                <li class="list-group-item">Inbox</li>
                                <li class="list-group-item">Work</li>
                                <li class="list-group-item">Shopping</li>
                            </ul>
                            <ul class="list-group list-group-horizontal-md justify-content-center mb-3">
                                <li class="list-group-item">Inbox</li>
                                <li class="list-group-item">Work</li>
                                <li class="list-group-item">Shopping</li>
                            </ul>
                            <ul class="list-group list-group-horizontal-md justify-content-end">
                                <li class="list-group-item">Inbox</li>
                                <li class="list-group-item">Work</li>
                                <li class="list-group-item">Shopping</li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 275px;"><code>&lt;!-- Start Alignment --&gt;
&lt;b-list-group horizontal=&quot;md&quot;&gt;
&lt;b-list-group-item&gt;Inbox&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Work&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Shopping&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>

<code>&lt;!-- Center Alignment --&gt;
&lt;b-list-group horizontal=&quot;md&quot; class=&quot;justify-content-center&quot;&gt;
&lt;b-list-group-item&gt;Inbox&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Work&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Shopping&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>

<code>&lt;!-- Right Alignment --&gt;
&lt;b-list-group horizontal=&quot;md&quot; class=&quot;justify-content-end&quot;&gt;
&lt;b-list-group-item&quot;list-group-item&quot;&gt;Inbox&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;list-group-item&quot;&gt;Work&lt;/b-list-group-item&gt;
&lt;b-list-group-item&quot;list-group-item&quot;&gt;Shopping&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Contextual Classes</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="contextuallist-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="contextuallist-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">Use contextual classes to style list items with a stateful background and
                            color.</p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item list-group-item-primary">A simple primary list group item
                                </li>
                                <li class="list-group-item list-group-item-secondary">A simple secondary list group item
                                </li>
                                <li class="list-group-item list-group-item-success">A simple success list group item
                                </li>
                                <li class="list-group-item list-group-item-danger">A simple danger list group item</li>
                                <li class="list-group-item list-group-item-warning">A simple warning list group item
                                </li>
                                <li class="list-group-item list-group-item-info">A simple info list group item</li>
                                <li class="list-group-item list-group-item-light">A simple light list group item</li>
                                <li class="list-group-item list-group-item-dark">A simple dark list group item</li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group&gt;
&lt;b-list-group-item&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;primary&quot;&gt;A simple primary list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;secondary&quot;&gt;A simple secondary list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;success&quot;&gt;A simple success list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;danger&quot;&gt;A simple danger list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;warning&quot;&gt;A simple warning list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;info&quot;&gt;A simple info list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;light&quot;&gt;A simple light list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item variant=&quot;dark&quot;&gt;A simple dark list group item&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Contextual Classes with Link</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="contextuallist-withlink-showcode" class="form-label text-muted">Show
                                    Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="contextuallist-withlink-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Contextual classes also work with
                            <code>.list-group-item-action</code>. Note the addition of the hover styles here not present
                            in the previous example.
                        </p>
                        <div class="live-preview">
                            <div class="list-group">
                                <b-link href="#" class="list-group-item list-group-item-action">A simple default list
                                    group
                                    item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-primary">
                                    A
                                    simple primary list group item</b-link>
                                <b-link href="#"
                                    class="list-group-item list-group-item-action list-group-item-secondary">A
                                    simple secondary list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-success">
                                    A
                                    simple success list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-danger">A
                                    simple danger list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-warning">
                                    A
                                    simple warning list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-info">A
                                    simple
                                    info list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-light">A
                                    simple light list group item</b-link>
                                <b-link href="#" class="list-group-item list-group-item-action list-group-item-dark">A
                                    simple
                                    dark list group item</b-link>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group&gt;
&lt;list-group-item href=&quot;#&quot; class=&quot;list-group-item-action&quot;&gt;A simple default list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;primary&quot; class=&quot;list-group-item-action&quot;&gt;A simple primary list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;secondary&quot; class=&quot;list-group-item-action&quot;&gt;A simple secondary list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;success&quot; class=&quot;list-group-item-action&quot;&gt;A simple success list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;danger&quot; class=&quot;list-group-item-action&quot;&gt;A simple danger list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;warning&quot; class=&quot;list-group-item-action&quot;&gt;A simple warning list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;info&quot; class=&quot;list-group-item-action&quot;&gt;A simple info list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;light&quot; class=&quot;list-group-item-action&quot;&gt;A simple light list group item&lt;/list-group-item&gt;
&lt;list-group-item href=&quot;#&quot; variant=&quot;dark&quot; class=&quot;list-group-item-action&quot;&gt;A simple dark list group item&lt;/list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Colored Lists</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="colored-list-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="colored-list-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>list-group-fill-</code> with modifier class to style list items with a stateful
                            background and color.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item">Dapibus ac facilisis in</li>
                                <li class="list-group-item list-group-fill-primary">A simple primary list group item
                                </li>
                                <li class="list-group-item list-group-fill-secondary">A simple secondary list group item
                                </li>
                                <li class="list-group-item list-group-fill-success">A simple success list group item
                                </li>
                                <li class="list-group-item list-group-fill-danger">A simple danger list group item</li>
                                <li class="list-group-item list-group-fill-warning">A simple warning list group item
                                </li>
                                <li class="list-group-item list-group-fill-info">A simple info list group item</li>
                                <li class="list-group-item list-group-fill-light">A simple light list group item</li>
                                <li class="list-group-item list-group-fill-dark">A simple dark list group item</li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup"><code>&lt;b-list-group&gt;
&lt;b-list-group-item &gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-primary&quot;&gt;A simple primary list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-secondary&quot;&gt;A simple secondary list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-success&quot;&gt;A simple success list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-danger&quot;&gt;A simple danger list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-warning&quot;&gt;A simple warning list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-info&quot;&gt;A simple info list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-light&quot;&gt;A simple light list group item&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-fill-dark&quot;&gt;A simple dark list group item&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xl="12">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Custom Content</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="customcontent-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="customcontent-showcode" />
                            </div>
                        </div>
                    </b-card-header>

                    <b-card-body>
                        <p class="text-muted">
                            Add nearly any HTML within, even for linked list groups like the one below, with the help of
                            <b-link href="https://getbootstrap.com/docs/5.1/utilities/flex/">flexbox utilities</b-link>.
                        </p>
                        <div class="live-preview">
                            <div class="list-group">
                                <b-link href="javascript:void(0);"
                                    class="list-group-item list-group-item-action active">
                                    <div class="float-end">Pending</div>
                                    <div class="d-flex mb-2 align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-1.jpg" alt
                                                class="avatar-sm rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="list-title fs-15 mb-1">Charlie Pritchard</h5>
                                            <p class="list-text mb-0 fs-12">12 min Ago</p>
                                        </div>
                                    </div>
                                    <p class="list-text mb-0">They all have something to say beyond the words on the
                                        page. They can come across as casual or neutral, exotic or graphic. That's why
                                        it's important to think about your message, then choose a font that fits. Cosby
                                        sweater eu banh mi, qui irure terry richardson ex squid.</p>
                                </b-link>
                                <b-link href="javascript:void(0);" class="list-group-item list-group-item-action">
                                    <div class="float-end">Pending</div>
                                    <div class="d-flex mb-2 align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-2.jpg" alt
                                                class="avatar-sm rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="list-title fs-15 mb-1">Dominic Charlton</h5>
                                            <p class="list-text mb-0 fs-12">12 min Ago</p>
                                        </div>
                                    </div>
                                    <p class="list-text mb-0">Trust fund seitan letterpress, keytar raw denim keffiyeh
                                        etsy art party before they sold out master cleanse gluten-free squid scenester
                                        freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf
                                        cliche high life echo park Austin.</p>
                                </b-link>
                                <b-link href="javascript:void(0);" class="list-group-item list-group-item-action">
                                    <div class="float-end">Rejected</div>
                                    <div class="d-flex mb-2 align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-3.jpg" alt
                                                class="avatar-sm rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="list-title fs-15 mb-1">Declan Long</h5>
                                            <p class="list-text mb-0 fs-12">12 min Ago</p>
                                        </div>
                                    </div>
                                    <p class="list-text mb-0">Big July earthquakes confound zany experimental vow. My
                                        girl wove six dozen plaid jackets before she quit. Six big devils from Japan
                                        quickly forgot how to waltz. try again until it looks right, and each assumenda
                                        labore aes Homo nostrud organic, assumenda labore aesthetic magna elements,
                                        buttons, everything.</p>
                                </b-link>
                                <b-link href="javascript:void(0);" class="list-group-item list-group-item-action">
                                    <div class="float-end">Successful</div>
                                    <div class="d-flex mb-2 align-items-center">
                                        <div class="flex-shrink-0">
                                            <img src="@/assets/images/users/avatar-4.jpg" alt
                                                class="avatar-sm rounded-circle" />
                                        </div>
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="list-title fs-15 mb-1">Angela Bernier</h5>
                                            <p class="list-text mb-0 fs-12">5 days Ago</p>
                                        </div>
                                    </div>
                                    <p class="list-text mb-0">Just like in the image where we talked about using
                                        multiple fonts, you can see that the background in this graphic design is
                                        blurred. Whenever you put text on top of an image, it’s important that your
                                        viewers can understand the text, and sometimes that means applying a gaussian
                                        readable.</p>
                                </b-link>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 300px;">
<code>&lt;b-list-group&gt;
&lt;b-list-group-item href=&quot;javascript:void(0);&quot; class=&quot;list-group-item-action active&quot;&gt;
&lt;div class=&quot;float-end&quot;&gt;
Pending 
&lt;/div&gt;
&lt;div class=&quot;d-flex mb-2 align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-sm rounded-circle&quot; /&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-3&quot;&gt;
&lt;h5 class=&quot;list-title fs-15 mb-1&quot;&gt;Charlie Pritchard&lt;/h5&gt;
&lt;p class=&quot;list-text mb-0 fs-12&quot;&gt;12 min Ago&lt;/p&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;p class=&quot;list-text mb-0&quot;&gt;They all have something to say beyond the words on the page. They can come across as casual or neutral, exotic or graphic. That's why it's important to think about your message, then choose a font that fits. Cosby sweater eu banh mi, qui irure terry richardson ex squid.&lt;/p&gt;
&lt;/b-list-group-item&gt;</code>
<code>&lt;b-list-group-item href=&quot;javascript:void(0);&quot; class=&quot;list-group-item-action&quot;&gt;
&lt;div class=&quot;float-end&quot;&gt;
Pending 
&lt;/div&gt;
&lt;div class=&quot;d-flex mb-2 align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-sm rounded-circle&quot; /&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-3&quot;&gt;
&lt;h5 class=&quot;list-title fs-15 mb-1&quot;&gt;Dominic Charlton&lt;/h5&gt;
&lt;p class=&quot;list-text mb-0 fs-12&quot;&gt;12 min Ago&lt;/p&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;p class=&quot;list-text mb-0&quot;&gt;Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin.&lt;/p&gt;
&lt;/b-list-group-item&gt;</code>
<code>&lt;b-list-group-item href=&quot;javascript:void(0);&quot; class=&quot;list-group-item-action&quot;&gt;
&lt;div class=&quot;float-end&quot;&gt;
Rejected 
&lt;/div&gt;
&lt;div class=&quot;d-flex mb-2 align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-sm rounded-circle&quot; /&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-3&quot;&gt;
&lt;h5 class=&quot;list-title fs-15 mb-1&quot;&gt;Declan Long&lt;/h5&gt;
&lt;p class=&quot;list-text mb-0 fs-12&quot;&gt;12 min Ago&lt;/p&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;p class=&quot;list-text mb-0&quot;&gt;Big July earthquakes confound zany experimental vow. My girl wove six dozen plaid jackets before she quit. Six big devils from Japan quickly forgot how to waltz. try again until it looks right, and each assumenda labore aes Homo nostrud organic, assumenda labore aesthetic magna elements, buttons, everything.&lt;/p&gt;
&lt;/b-list-group-item&gt;</code>
<code>&lt;b-list-group-item href=&quot;javascript:void(0);&quot; class=&quot;list-group-item-action&quot;&gt;
&lt;div class=&quot;float-end&quot;&gt;
Successful 
&lt;/div&gt;
&lt;div class=&quot;d-flex mb-2 align-items-center&quot;&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;img src=&quot;...&quot; alt=&quot;&quot; class=&quot;avatar-sm rounded-circle&quot; /&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-grow-1 ms-3&quot;&gt;
&lt;h5 class=&quot;list-title fs-15 mb-1&quot;&gt;Angela Bernier&lt;/h5&gt;
&lt;p class=&quot;list-text mb-0 fs-12&quot;&gt;5 days Ago&lt;/p&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;p class=&quot;list-text mb-0&quot;&gt;Just like in the image where we talked about using multiple fonts, you can see that the background in this graphic design is blurred. Whenever you put text on top of an image, it&rsquo;s important that your viewers can understand the text, and sometimes that means applying a gaussian readable.&lt;/p&gt;
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Badges</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withbadgeslist-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withbadgeslist-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use badges to any list group item to show unread counts, activity, and more with the help of
                            some
                            <b-link href="https://getbootstrap.com/docs/5.1/utilities/">utilities</b-link>.
                        </p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Send the billing agreement
                                    <b-badge variant="success">High</b-badge>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">Send over
                                    all the documentation</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Meeting with daron to review the intake form
                                    <b-badge variant="danger">Low</b-badge>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Check uikings theme and give customer support
                                    <b-badge variant="secondary">Medium</b-badge>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Start making a presentation
                                    <b-badge variant="success">High</b-badge>
                                </li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 200px;"><code>&lt;b-list-group&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
Send the billing agreement &lt;span class=&quot;badge bg-success&quot;&gt;High&lt;/span&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
Send over all the documentation 
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
Meeting with daron to review the intake form &lt;span class=&quot;badge bg-danger&quot;&gt;Low&lt;/span&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
Check uikings theme and give customer support &lt;span class=&quot;badge bg-secondary&quot;&gt;Medium&lt;/span&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
Start making a presentation &lt;span class=&quot;badge bg-success&quot;&gt;High&lt;/span&gt;
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Checkboxs</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withcheckboxs-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withcheckboxs-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">Use Bootstrap’s checkboxes within list group items and customize as
                            needed.</p>
                        <div class="live-preview">
                            <div class="list-group">
                                <label class="list-group-item">
                                    <input class="form-check-input me-1" type="checkbox" value />
                                    Declined Payment
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input me-1" type="checkbox" value checked />
                                    Delivery Error
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input me-1" type="checkbox" value checked />
                                    Wrong Amount
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input me-1" type="checkbox" value />
                                    Wrong Address
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input me-1" type="checkbox" value />
                                    Wrong UX/UI Solution
                                </label>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 275px;">
<code>&lt;b-list-group&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot;&gt;
Declined Payment
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; checked&gt;
Delivery Error
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; checked&gt;
Wrong Amount
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot;&gt;
Wrong Address
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot;&gt;
Wrong UX/UI Solution
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Radios</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withradios-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withradios-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">Use Bootstrap’s radios within list group items and customize as needed.
                        </p>
                        <div class="live-preview">
                            <div class="list-group">
                                <label class="list-group-item">
                                    <input class="form-check-input fs-14 mt-0 align-middle me-1" name="exampleRadios"
                                        type="radio" value />
                                    Declined Payment
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input fs-14 mt-0 align-middle me-1" name="exampleRadios"
                                        type="radio" value checked />
                                    Delivery Error
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input fs-14 mt-0 align-middle me-1" name="exampleRadios"
                                        type="radio" value />
                                    Wrong Amount
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input fs-14 mt-0 align-middle me-1" name="exampleRadios"
                                        type="radio" value />
                                    Wrong Address
                                </label>
                                <label class="list-group-item">
                                    <input class="form-check-input fs-14 mt-0 align-middle me-1" name="exampleRadios"
                                        type="radio" value />
                                    Wrong UX/UI Solution
                                </label>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 275px;">
<code>&lt;b-list-group&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; name=&quot;exampleRadios&quot; type=&quot;radio&quot; value=&quot;&quot;&gt;
Declined Payment
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; name=&quot;exampleRadios&quot; type=&quot;radio&quot; value=&quot;&quot; checked&gt;
Delivery Error
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; name=&quot;exampleRadios&quot; type=&quot;radio&quot; value=&quot;&quot;&gt;
Wrong Amount
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; name=&quot;exampleRadios&quot; type=&quot;radio&quot; value=&quot;&quot;&gt;
Wrong Address
&lt;/b-list-group-item&gt;
&lt;b-list-group-item tag=&quot;label&quot;&gt;
&lt;input class=&quot;form-check-input me-1&quot; name=&quot;exampleRadios&quot; type=&quot;radio&quot; value=&quot;&quot;&gt;
Wrong UX/UI Solution
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Icon</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withbadgeslist2-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withbadgeslist2-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">Use icons to any list group item to show icons to list group items.</p>
                        <div class="live-preview">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> Send the billing agreement
                                </li>
                                <li class="list-group-item">
                                    <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> Send over all the
                                    documentation.
                                </li>
                                <li class="list-group-item">
                                    <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> Meeting with daron to
                                    review the intake form
                                </li>
                                <li class="list-group-item">
                                    <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> Check uikings theme and
                                    give customer support
                                </li>
                                <li class="list-group-item">
                                    <i class="mdi mdi-check-bold align-middle lh-1 me-2"></i> Start making a
                                    presentation
                                </li>
                            </ul>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup">
<code>&lt;b-list-group&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;mdi mdi-check-bold align-middle lh-1 me-2&quot;&gt;&lt;/i&gt; Send the billing agreement&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;mdi mdi-check-bold align-middle lh-1 me-2&quot;&gt;&lt;/i&gt; Send over all the documentation.&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;mdi mdi-check-bold align-middle lh-1 me-2&quot;&gt;&lt;/i&gt; Meeting with daron to review the intake form&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;mdi mdi-check-bold align-middle lh-1 me-2&quot;&gt;&lt;/i&gt; Check uikings theme and give customer support&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;&lt;i class=&quot;mdi mdi-check-bold align-middle lh-1 me-2&quot;&gt;&lt;/i&gt; Start making a presentation&lt;/b-list-group-item&gt;
&lt;/ul&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">List with Numbered</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withnumbered-showcode" class="form-label text-muted">Show Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withnumbered-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p class="text-muted">
                            Use
                            <code>list-group-numbered</code> class (optionally use an
                            <code>&lt;ol&gt;</code> element) to show numbered list group items.
                        </p>
                        <div class="live-preview">
                            <ol class="list-group list-group-numbered">
                                <li class="list-group-item">Send the billing agreement</li>
                                <li class="list-group-item">Send over all the documentation.</li>
                                <li class="list-group-item">Meeting with daron to review the intake form</li>
                                <li class="list-group-item">Check uikings theme and give customer support</li>
                                <li class="list-group-item">Start making a presentation</li>
                            </ol>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup">
<code>&lt;b-list-group class=&quot;list-group-numbered&quot;&gt;
&lt;b-list-group-item&gt;Send the billing agreement&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Send over all the documentation.&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Meeting with daron to review the intake form&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Check uikings theme and give customer support&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Start making a presentation&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col xxl="4" xl="6">
                <b-card no-body>
                    <b-card-header class="align-items-center d-flex">
                        <b-card-title class="mb-0 flex-grow-1">Custom Content Lists</b-card-title>
                        <div class="flex-shrink-0">
                            <div class="form-check form-switch form-switch-right form-switch-md">
                                <label for="withcustomcontent-list-showcode" class="form-label text-muted">Show
                                    Code</label>
                                <input class="form-check-input code-switcher" type="checkbox"
                                    id="withcustomcontent-list-showcode" />
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <p>
                            Add nearly any HTML within, even for linked list groups like the one below, with the help of
                            <b-link href="https://getbootstrap.com/docs/5.1/utilities/flex/">flexbox utilities</b-link>.
                        </p>
                        <div class="live-preview">
                            <div data-simplebar style="max-height: 215px;">
                                <ul class="list-group mb-1">
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div class="avatar-title bg-soft-danger text-danger rounded">
                                                            <i class="ri-netflix-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">Netfilx</h6>
                                                        <small class="text-muted">2 min Ago</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <span class="text-danger">-$25.50</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div class="avatar-title bg-soft-success text-success rounded">
                                                            <i class="ri-spotify-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">Spotify</h6>
                                                        <small class="text-muted">5 days Ago</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <span class="text-success">$48.25</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="@/assets/images/users/avatar-2.jpg" alt
                                                            class="avatar-xs rounded" />
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">Emily Slater</h6>
                                                        <small class="text-muted">8 days Ago</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <span class="text-success">$354.90</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div
                                                            class="avatar-title bg-soft-secondary text-secondary rounded">
                                                            <i class="ri-paypal-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">Paypal</h6>
                                                        <small class="text-muted">1 month Ago</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <span class="text-danger">-$12.22</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-grow-1">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="@/assets/images/users/avatar-3.jpg" alt
                                                            class="avatar-xs rounded" />
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <h6 class="fs-14 mb-0">Harvey Wells</h6>
                                                        <small class="text-muted">4 month Ago</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <span class="text-success">-$459.78</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-none code-view">
                            <pre class="language-markup" style="height: 215px;">
<code>&lt;div data-simplebar style=&quot;max-height: 215px;&quot;&gt; 
&lt;b-list-group&gt;
&lt;b-list-group-item class=&quot;list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;div class=&quot;d-flex&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 avatar-xs&quot;&gt;
        &lt;div class=&quot;avatar-title bg-soft-danger text-danger rounded&quot;&gt;
            &lt;i class=&quot;ri-netflix-fill&quot;&gt;&lt;/i&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-shrink-0 ms-2&quot;&gt;
        &lt;h6 class=&quot;fs-14 mb-0&quot;&gt;Netfilx&lt;/h6&gt;
        &lt;small class=&quot;text-muted&quot;&gt;2 min Ago&lt;/small&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;span class=&quot;text-danger&quot;&gt;-$25.50&lt;/span&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;div class=&quot;d-flex&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 avatar-xs&quot;&gt;
        &lt;div class=&quot;avatar-title bg-soft-success text-success rounded&quot;&gt;
            &lt;i class=&quot;ri-spotify-fill&quot;&gt;&lt;/i&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-shrink-0 ms-2&quot;&gt;
        &lt;h6 class=&quot;fs-14 mb-0&quot;&gt;Spotify&lt;/h6&gt;
        &lt;small class=&quot;text-muted&quot;&gt;5 days Ago&lt;/small&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;span class=&quot;text-success&quot;&gt;$48.25&lt;/span&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;div class=&quot;d-flex&quot;&gt;
    &lt;div class=&quot;flex-shrink-0&quot;&gt;
        &lt;img src=&quot;assets/images/users/avatar-2.jpg&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded&quot; /&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-shrink-0 ms-2&quot;&gt;
        &lt;h6 class=&quot;fs-14 mb-0&quot;&gt;Emily Slater&lt;/h6&gt;
        &lt;small class=&quot;text-muted&quot;&gt;8 days Ago&lt;/small&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;span class=&quot;text-success&quot;&gt;$354.90&lt;/span&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;div class=&quot;d-flex&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 avatar-xs&quot;&gt;
        &lt;div class=&quot;avatar-title bg-soft-secondary text-secondary rounded&quot;&gt;
            &lt;i class=&quot;ri-paypal-fill&quot;&gt;&lt;/i&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-shrink-0 ms-2&quot;&gt;
        &lt;h6 class=&quot;fs-14 mb-0&quot;&gt;Paypal&lt;/h6&gt;
        &lt;small class=&quot;text-muted&quot;&gt;1 month Ago&lt;/small&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;span class=&quot;text-danger&quot;&gt;-$12.22&lt;/span&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;list-group-item&quot;&gt;
&lt;div class=&quot;d-flex align-items-center&quot;&gt;
&lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;div class=&quot;d-flex&quot;&gt;
    &lt;div class=&quot;flex-shrink-0&quot;&gt;
        &lt;img src=&quot;assets/images/users/avatar-3.jpg&quot; alt=&quot;&quot; class=&quot;avatar-xs rounded&quot; /&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-shrink-0 ms-2&quot;&gt;
        &lt;h6 class=&quot;fs-14 mb-0&quot;&gt;Harvey Wells&lt;/h6&gt;
        &lt;small class=&quot;text-muted&quot;&gt;4 month Ago&lt;/small&gt;
    &lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;div class=&quot;flex-shrink-0&quot;&gt;
&lt;span class=&quot;text-success&quot;&gt;-$459.78&lt;/span&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;
&lt;/div&gt;</code></pre>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </Layout>
</template>